<template>
  <DataTable v-if="created" :options="options" />
</template>

<script>
import { ScriptService } from '@tutti/services';
export default {
  data() {
    return {
      created: false,
      options: {
        action: {
          downloadInvoice: true,
          viewInvoice: true,
          hideEdit: true,
          hideView: true,
        },
        hideHeader: {
          created: true,
          updated: true,
        },
        export: false,
        sync: false,
        filters: [],
        headers: [
          {
            text: 'Type',
            value: 'type',
            sortable: false,
            component: { name: 'dtView' },
          },
          {
            text: 'Booking number',
            value: 'bookingId',
            sortable: false,
            component: { name: 'dtView', props: { name: 'booking', permission: 'booking' } },
          },
          {
            text: 'From',
            value: 'from',
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'To',
            value: 'to',
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            capitalize: true,
          },
          {
            text: 'Date',
            value: 'data.information.date',
            sortable: true,
          },
          {
            text: 'Due date',
            value: 'data.information.due-date',
            sortable: false,
          },
        ],
      },
    };
  },
  created() {
    this.created = true;
  },
  async mounted() {
    await ScriptService.loadPDFDist();
    await ScriptService.loadPDFDistWorker();
    await ScriptService.loadEasyInvoice();
  },
};
</script>

<style>
#pdf {
  text-align: center;
}

#pdf canvas {
  border: 1px solid black;
  width: 100%;
}
</style>
